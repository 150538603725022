<template>
  <page v-loading="loading">
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="出库单号" prop="invoiceNo">
          <el-input v-model="params.invoiceNo" />
        </el-form-item>
        <el-form-item label="出库类型" prop="invoiceMethod">
          <el-select v-model="params.invoiceMethod" clearable>
            <el-option value="100" label="退货出库"></el-option>
            <el-option value="101" label="其他出库"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出库仓库" prop="groupId">
          <el-select v-model="params.groupId" clearable>
            <el-option
              v-for="(itme, index) in groupList"
              :key="index"
              :value="itme.value"
              :label="itme.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作时间" prop="date">
          <el-date-picker
            v-model="params.date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="发起人" prop="creator">
          <el-input v-model="params.creator" />
        </el-form-item>
        <el-button type="primary" @click="goAdd"> 新增 </el-button>
        <!-- <el-button type="primary" @click="visible = true">
          导入出库单
        </el-button> -->
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table :data="tableData" border height="100%" style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="invoiceNo"
          label="出库单号"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="invoiceOrder"
          label="进货单号"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="invoiceMethodDesc"
          label="出库类型"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="groupName"
          label="出库仓库"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="creator"
          label="发起人"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="createTime"
          label="操作时间"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="remark"
          label="备注"
        ></el-table-column>

        <el-table-column width="220" label="操作">
          <template slot-scope="{ row }">
            <el-button @click="amountDetail(row)" type="text">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <DetailData
      v-if="dataVisible"
      :invoiceNo="invoiceNo"
      :visible.sync="dataVisible"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import DetailData from './components/DetailData.vue'

export default {
  mixins: [watchParamsGetData],
  components: {
    DetailData
  },
  data() {
    return {
      loading: false,
      currentItem: null,
      visible: false,
      invoiceNo: '',
      dataVisible: false,
      groupList: [],
      tableData: [],
      params: {
        invoiceType: 2,
        limit: 20,
        page: 1
      },
      totalCount: 0
    }
  },

  created() {
    this.getRkuData()
    this.getData()
  },
  methods: {
    async getRkuData() {
      try {
        const res = await this.$api.groupList.getGroupAllList()
        this.groupList = (res.group || []).map(item => {
          return {
            label: item.groupName,
            value: item.id
          }
        })
        console.log('this.groupList', this.groupList)
      } catch (err) {}
    },
    goAdd() {
      this.$router.push({
        name: 'addOutbound'
      })
    },
    async getData(query) {
      try {
        this.loading = true
        let params = { ...this.params }
        if (params.date && params.date.length) {
          params.createTimeEnd = this.$utils.dateFormat(
            params.date[1],
            'YYYY-MM-DD HH:mm:ss'
          )
          params.createTimeStart = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD HH:mm:ss'
          )
        } else {
          params.createTimeEnd = undefined
          params.createTimeStart = undefined
        }
        if (query) {
          params.limit = 20
          params.page = 1
        }
        const res = await this.$api.commodity.getCommodityList(params)
        if (res.page && res.page.list) {
          this.tableData = res.page.list.map(item => {
            return item
          })
        }
        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    amountDetail(row) {
      this.dataVisible = true
      this.invoiceNo = row.invoiceNo
    }
  }
}
</script>

<style scoped lang="scss"></style>
